import { CorporationStatus } from "~/enum";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const nuxtApp = useNuxtApp();
    let me = nuxtApp.$me.get();
    let path = null;

    if (! nuxtApp.$me.check()) {
        path = 'register-buyer';
    } else if (! [CorporationStatus.WAITING_INFO, CorporationStatus.WAITING].includes(me?.corporation?.status)) {
        console.warn('Waiting middleware: Corporation status is not waiting info', me?.corporation?.status);
        try {
            let previousRoute = useRoute().redirectedFrom;
            console.error('Previous route logged: ', previousRoute);
            path = previousRoute ? previousRoute.fullPath : 'index';
        } catch (e) {
            console.error('Error in waiting middleware', e);
        }
    }

    if (path) {
        let redirect = await nuxtApp.$localePath(path);
        return await navigateTo(redirect);
    }
})
